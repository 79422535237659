@import "~bulma/css/bulma.min.css";
@font-face {
  font-family: 'NotoSansJP';
  src: url('../public/fonts/Noto_Sans_JP/NotoSansJP-Regular.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'NotoSansJP', 'Helvetica', 'Arial', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000 !important;
  color: #fff !important;
  font-size: 1.2vw !important;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:focus {
  outline: none;
}
select:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
a:hover {
  color: #fff;
}
h4 {
  font-size: 1.7em;
  font-weight: bold;
  margin: 1em 0;
  padding-bottom: 0.3em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
h5 {
  font-size: 1.5em;
  margin: 0.8em 0;
  font-weight: bold;
}
pre {
  padding: 0.8em 1em;
  margin: 0.8em 0;
  border-radius: 10px;
  background: #dcdcdc;
}
.dpNone {
  display: none;
}

.loadingArea {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.loader {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 2em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  border: none;
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.cover-side::after {
  content: "";
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0;
}
.cover-side.inview::after {
  opacity: 1;
  -webkit-animation-name: kf-cover-slide;
          animation-name: kf-cover-slide;
  -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@keyframes kf-cover-slide {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  50% {
    transform-origin: left;
    transform: scaleX(1);
  }
  50.1% {
    transform-origin: right;
    transform: scaleX(1);
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}
.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  top: 5vw;
  opacity: 0;
}

.arrows path {
  stroke: #fff;
  fill: transparent;
  stroke-width: 1px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}
.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 { 
  animation-delay:0s;
}

.arrow {
  position: absolute;
  top: 50%;
  background: rgba(255,255,255,0.4);
  z-index:9;
  padding: 1.3em 0.1em;
  line-height: 1;
  font-size: 3em;
  color: #000;
  cursor: pointer;
  transition: all 0.3s;
}
.arrow:hover {
  background: rgba(255,255,255,0.6);
  transition: all 0.3s;
}
.arrow--left {
  left: 1%;
}
.arrow--right {
  right: 1%;
}
.slick-slider {
  padding: 0 6em;
}
.slick-slide {
  margin-top: 11em;
}
.contentTitle {
  color: #fff;
  z-index: 10;
  position: absolute;
  font-size:7em;
  letter-spacing: 0.03em;
  opacity: 0;
}
.slidesNav ul li {
  height: 2em;
  width: 2em;
}
#fp-nav ul li {
  width: 2em !important;
  height: 2em !important;
}
#fp-nav ul li a.active span {
  background: #fff;
  height: 2em !important;
  width: 2em !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%) !important;
  margin: 0 !important;
}
#fp-nav ul li a span {
  background-color: #fff !important;
  height: 1em !important;
  width: 1em !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%) !important;
  margin: 0 !important;
}
.fp-warning, .fp-watermark {
  display: none;
}
.navLink {
  color: #fff;
  margin-left: 1em;
  position: relative;
}
.navLink::after {
  content: '';
  /*絶対配置で線の位置を決める*/
  position: absolute;
  bottom: -0.5vw;
  left: 0%;
  /*線の形状*/
  width: 100%;
  height: 2px;
  background:#fff;
  /*アニメーションの指定*/
  transition: all .3s;
  transform: scale(0, 1);/*X方向0、Y方向1*/
  transform-origin: left top;/*左上基点*/
}
.navLink:hover::after {
  transform: scale(1, 1);
}
.activeLink {
  font-weight: bold;
  padding-bottom: 0.25em;
  border-bottom: 3px  solid #fff;
  color: #fff;
  margin-left: 1em;
  position: relative;
}
.mainBtnArea {
  overflow-x: hidden;
}
.mainBtn {
  display:inline-block;
  background:#E05F73;
  color: #fff;
  white-space: nowrap;
  width: 18%;
  padding: 0.3em 0 0.5em 0;
  text-align:center;
  cursor:pointer;
  position: relative;
  border-radius: 9999px;
  font-size: 1.5em;
  z-index: 10;
  box-sizing: border-box;
}
.mainBtn::after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 5px);
  right: 2.5vw;
  width: 1.5vw;
  height: 5px;
  border: none;
  border-right: 2px solid #FFF;
  border-bottom: 1px solid #FFF;
  transform: skew(45deg);
  transition: .3s;
}
.mainBtn:hover::after {
  right: 2vw;
  width: 2vw;
}
.subBtn {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 17%;
  padding: 0.5em 0;
  font-size: 1.5em;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  border: 1px solid #fff;
  background : #000;
  margin-top: 2em;
  transition: .6s;
}
.subBtn span {
  position: relative;
  z-index: 3;
}
.subBtn:hover {
  color: #000;
}
.bgLeft:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background:#fff;
  width: 100%;
  height: 100%;
  transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: right top;
}
.bgLeft:hover:before{
  transform-origin:left top;
  transform:scale(1, 1);
}
.bgRight:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background:  #fff;
  width: 100%;
  height: 100%;
  transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: left top;
}
.bgRight:hover:before{
  transform-origin:right top;
  transform:scale(1, 1);
}
.leftSubBtn:hover {
  padding-right: 2em;
}
.rightSubBtn:hover {
  padding-left: 2em;
}
.leftSubBtnArrow {
  vertical-align: bottom;
  margin-right: 0.5em;
}
.rightSubBtnArrow {
  vertical-align: bottom;
  margin-left: 0.5em;
}
.subContentsTitle {
  position: absolute;
  font-size: 6em;
  background: rgba(112, 112, 112, 0.7);
  line-height: 1.2;
  padding: 0.1em 1.5em;
}
.show {
  opacity: 1 !important;
}
.titleDelay {
  animation-delay: 1.5s;
}
.delay {
  animation-delay: 2s;
}
.fadeUp{
  animation-name:fadeUpAnime;
  animation-duration:0.5s;
  animation-fill-mode:forwards;
  opacity:0;
  }
  
  @keyframes fadeUpAnime{
    from {
      opacity: 0;
    transform: translateY(50px);
    }
  
    to {
      opacity: 1;
    transform: translateY(0);
    }
  }
  
  .iconAnime {
    opacity: 1;
    animation-name: rotateYAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
  }
  @keyframes rotateYAnime {
    from {
      transform: rotateY(0);
      opacity: 0;
    }
    to {
      transform: rotateY(-360deg);
      opacity: 1;
    }
  }

  @media screen and (min-width: 481px) {
    .sp {
      display: none;
    }
  }

@media screen and (max-width: 480px) {
  .pc {
    display: none;
  }
  .hbMenuLine {
    display: block;
    position: absolute;
    width: 80%;
    height: 3px;
    background: #fff;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s;
  }
  .topLine {
    top: 20%;
  }
  .secondLine {
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .thirdLine {
    bottom: 20%;
  }
  .topLine.active {
    top: 50%;
    left: 10%;
    transform: rotate(-45deg);
  }
  .secondLine.active {
    display: none;
  }
  .thirdLine.active {
    bottom: 45%;
    left: 10%;
    transform: rotate(45deg);
  }
  .spDrawerMenuArea {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0,0,0,0.6);
    z-index: -1;
    opacity: 0;
    overflow: hidden;
  }
  .spDrawerMenuArea.active {
    opacity: 1;
    display: block;
    z-index: 10000;
  }
  .spDrawerMenu {
    position: fixed;
    top: 0;
    left: -1000px;
    width: 60%;
    height: 100%;
    background: rgb(112, 112, 112);
    z-index: 10001;
    transition: all 0.5s;
    padding: 8em 3em;
  }
  .spDrawerMenu.active {
    left: 0;
    transition: all 0.5s;
  }
  .mb-3em {
    margin-bottom: 3em;
  }
  .fp-overflow {
    margin-top: -40vw;
    overflow-y: visible !important;
  }
  .arrows {
    top: 15vw;
  }
  .react-typewriter-text {
    font-size: 10em !important;
  }
  #fp-nav {
    top: 75% !important;
  }
  #fp-nav.fp-left {
    left: 10px !important;
  }
  .mainBtn {
    width: 60%;
    font-size: 4em;
    padding: 0.5em 0 0.5em 0
  }
  .mainBtn::after {
    width: 7vw;
    top: calc(50% - 3px);
    right: 4vw;
  }
  .contentTitle {
    font-size: 10em;
  }
  .slick-slider {
    padding: 0px 3em;
  }
  .arrow--left {
    display: none;
  }
  .arrow--right {
    display: none;
  }
  .subBtn {
    width: 55%;
    padding: 0.8em 0;
    font-size: 4em;
  }
}
.goodActive {
  border-color: yellow !important;
  background: rgba(255, 255, 255, 0.6) !important;
}
.goodActiveTxt {
  color: yellow !important;
}